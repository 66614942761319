// For GA4, event name must be  "like_this" format for example: "add_to_cart"
const logAnalyticsEvent = async ({ event_name }) => {

  if (!event_name) {
    return false
  }

  //Start pushing data to dataLayer
  window.dataLayer = window.dataLayer || []
  dataLayer.push({
    event: event_name,
  });
}

const pushCustomEvent = (event) => {
  // if server-side, throw error
  if (typeof window === 'undefined') {
    throw new Error('window is not defined')
  }
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(event)
  console.log('pushCustomEvent:', event)
}



export { logAnalyticsEvent, pushCustomEvent }
