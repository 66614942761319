'use client'

import { getCookie, setCookie } from 'cookies-next'
import { useEffect } from 'react'

const StagingGate = () => {
  const cookieKey = 'bhq_staging_access'

  useEffect(() => {
    const isAllowedOrigin =
      typeof window !== 'undefined' &&
      (window.location.origin === 'https://www.bbeyond.app' ||
        window.location.origin === 'https://www.bangkokhospital.com')

    if (isAllowedOrigin) {
      return
    }

    // Function to check the cookie
    const checkAccess = () => {
      const hasAccess = getCookie(cookieKey)

      // If there is no access cookie, prompt for password
      if (!hasAccess) {
        const password = prompt(
          'คุณกำลังเข้าเว็บไซต์ทดสอบของ BHQ กรุณากรอกรหัสผ่าน'
        )
        if (password === 'bhqtestonly') {
          // Set a cookie for 30 days on correct password
          setCookie(cookieKey, 'granted', {
            maxAge: 60 * 60 * 24 * 30,
            path: '/',
          })
          alert('Access granted!')
        } else {
          // Handle incorrect password
          alert(
            'รหัสผ่านผิดพลาด กรุณากรอกรหัสผ่านให้ถูกต้อง หากท่านไม่ใช่ผู้ทดสอบ'
          )
          checkAccess() // Re-prompt if wrong password
        }
      }
    }

    checkAccess()
  }, [])

  return null

  // if (!shouldGate) {
  //   return null
  // }

  // return (
  //   <div className="tw-fixed tw-w-fit tw-right-0 tw-bottom-4 tw-z-20 tw-select-none tw-pointer-events-none">
  //     <div className="tw-backdrop-blur-lg tw-bg-primary/80 tw-text-white tw-font-bold  tw-whitespace-nowrap tw-text-[0.6rem]  tw-px-2 tw-py-2 tw-rounded-l tw-text-center">
  //       TESTING
  //     </div>
  //   </div>
  // )
}

export default StagingGate
