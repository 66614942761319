// utils/dom-utils.js
export function findParentWithDataAttribute(element, attr) {
  while (element && element !== document.body) {
    const elementHasGetAttributeAsAFunction =
      typeof element?.getAttribute === 'function'
    if (
      elementHasGetAttributeAsAFunction &&
      element?.getAttribute(attr) !== null
    ) {
      return element
    }
    element = element.parentNode
  }
  return null // Return null if no element with the specified attribute is found
}
