import { NEXT_INSTANCE } from '@/lib/api'
import { create } from 'zustand'

const useFpd = create((set, get) => ({
  logEvent: async (data) => {
    try {
      const res = await NEXT_INSTANCE.post(`/next-api/auth/fpd/event`, data)
      return res.data
    } catch (err) {
      console.error(err?.response?.data)
      return err?.response?.data
    }
  },
  answerQuiz: async (data) => {
    try {
      const res = await NEXT_INSTANCE.post(`/next-api/auth/fpd/quiz`, data)
      return res.data
    } catch (err) {
      console.error(err?.response?.data)
      return err?.response?.data
    }
  },
  getArticleQuiz: async (postID) => {
    try {
      const res = await NEXT_INSTANCE.get(
        `/next-api/auth/fpd/quiz?post_id=${postID}`
      )
      return res.data
    } catch (err) {
      console.error(err?.response?.data)
      return err?.response?.data
    }
  },
  redeemReward: async (data) => {
    try {
      const res = await NEXT_INSTANCE.post(`/next-api/auth/fpd/reward`, data)
      return res.data
    } catch (err) {
      console.error(err?.response?.data)
      return err?.response?.data
    }
  },
}))

export default useFpd
