import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReCaptcha"] */ "/var/www/bhq-nextjs/node_modules/next-recaptcha-v3/lib/ReCaptcha.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReCaptchaContext","ReCaptchaProvider","useReCaptchaContext"] */ "/var/www/bhq-nextjs/node_modules/next-recaptcha-v3/lib/ReCaptchaProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReCaptcha"] */ "/var/www/bhq-nextjs/node_modules/next-recaptcha-v3/lib/useReCaptcha.js");
;
import(/* webpackMode: "eager", webpackExports: ["withReCaptcha"] */ "/var/www/bhq-nextjs/node_modules/next-recaptcha-v3/lib/withReCaptcha.js");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.jsx\",\"import\":\"Sarabun\",\"arguments\":[{\"variable\":\"--font-sarabun\",\"weight\":[\"300\",\"400\",\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"]}],\"variableName\":\"sarabun\"}");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/node_modules/next/font/local/target.css?{\"path\":\"src/utils/fonts-loader.js\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-satoshi\",\"src\":[{\"path\":\"../../public/fonts/Satoshi/Satoshi-Variable.woff2\",\"weight\":\"400 900\",\"style\":\"normal\",\"preload\":true,\"variable\":true}]}],\"variableName\":\"satoshi\"}");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts-loader.js\",\"import\":\"Figtree\",\"arguments\":[{\"variable\":\"--font-satoshiRealBold\",\"weight\":[\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"]}],\"variableName\":\"satoshiRealBold\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/bhq-nextjs/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/ClientStoreInitializer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/SeoRedirect.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/StagingGate.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/lib/mui-theme.js");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/src/styles/globals.css");
