'use client'
import { useTranslations } from 'next-intl'
import Image from 'next/image'

export default function LoadingState({ isFullPage = false, addedClass = '' }) {
  const __t = useTranslations()
  return (
    <div
      className={`center-child ${
        isFullPage ? 'tw-min-h-[80vh]' : 'tw-min-h-[40vh]'
      } ${addedClass}`}
    >
      <Image
        width={40}
        height={40}
        src="https://static.bangkokhospital.com/uploads/assets/images/loading-circle.svg"
        className="md:tw-size-32 tw-size-16 tw-m-3"
        alt="Loading"
      />
      <h4 className="tw-text-primary">{__t('loading')}</h4>
    </div>
  )
}
