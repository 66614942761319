'use client'

import useMeta from '@/store/meta'
import { useLocale } from 'next-intl'
import { useEffect, useState } from 'react'
import LoadingState from './main/element/LoadingState'

/*
กรอก Redirect Mapping ใน sheet นี้:
https://docs.google.com/spreadsheets/d/1yes_POuwD-L4jboY8zrpqcv_ofYbOtQ8Lq4xaBJprpA
*/

const SeoRedirect = ({ redirect = false }) => {
  //create isRedirecting state
  const [isRedirecting, setIsRedirecting] = useState(false)
  const locale = useLocale()
  const { seoRedirect } = useMeta()

  useEffect(() => {
    if (!seoRedirect) {
      return
    }
    //handle 404
    const redirectToHome = () => {
      if (
        redirect &&
        window.location.pathname !== '/' &&
        window.location.pathname !== `/${locale}`
      ) {
        window.location.href = `/${locale}`
      }
    }
    //list
    let redirectList = seoRedirect
    // reverse the list so the last item will be the first to check
    redirectList.reverse()
    //Clean up current url before comparing
    const currentUrl = window?.location?.href
      //we need no host url when comparing
      ?.replace(window?.location?.host, '')
      //we need no http or https when comparing
      ?.replace(/^https?:\/\//i, '')
      //if the last character is '/' remove it
      ?.replace(/\/$/, '')
    //Direct Case
    const directRedirect = redirectList
      ?.filter((x) => x.method === 'direct')
      ?.find((item) => currentUrl === item.from)
    //Regex Case
    const regexRedirect = redirectList
      .filter((x) => x.method === 'regex')
      ?.find((item) => {
        const regex = new RegExp(item.from)
        return regex.test(currentUrl)
      })
    //Redirect
    if (directRedirect) {
      setIsRedirecting(true)
      // replace the part of currentUrl to directRedirect.to
      window.location.href = currentUrl.replace(
        directRedirect.from,
        directRedirect.to
      )
    } else if (regexRedirect) {
      setIsRedirecting(true)
      // Regex pattern to match the source URL and capture the number
      let regex = new RegExp(regexRedirect.from)
      // Perform the redirect using the replace() method
      let redirectUrl = currentUrl
        .replace(regex, regexRedirect.to)
        .replace(/\/(\w{2})\/(\w{2})\//, '/$2/') // Replace if two locales are found
      window.location.href =
        window.location.protocol + '//' + window.location.host + redirectUrl
    } else {
      redirectToHome()
    }
    return () => {
      setIsRedirecting(false)
    }
  }, [isRedirecting, redirect, seoRedirect, locale])

  // loading
  if (isRedirecting) {
    return (
      <div className="tw-top-0 tw-left-0 tw-flex tw-w-full tw-h-full tw-z-50 tw-fixed tw-items-center tw-justify-center backdrop-filter -white">
        <LoadingState />
      </div>
    )
  }
  return null
}

export default SeoRedirect
