'use client'

import colors from '@/lib/colors'
import { createTheme } from '@mui/material/styles'
import { createBreakpoints } from '@mui/system'

// create function that determines text color based on background color
function getTextColor(color) {
  const colorValue = color.replace('#', '')
  const r = parseInt(colorValue.substr(0, 2), 16)
  const g = parseInt(colorValue.substr(2, 2), 16)
  const b = parseInt(colorValue.substr(4, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? '#000000' : '#ffffff'
}

function generatePalettes() {
  const palettes = {}
  Object.keys(colors).forEach((colorKey) => {
    palettes[colorKey] = {
      main: colors[colorKey],
      contrastText: getTextColor(colors[colorKey]),
    }
  })
  return palettes
}

const customBreakpointValues = {
  values: {
    xs: 440,
    sm: 768,
    md: 1080,
    lg: 1080,
    xl: 1080,
    '2xl': 1240,
  },
}
const breakpoints = createBreakpoints({ ...customBreakpointValues })

const theme = createTheme({
  breakpoints: {
    ...customBreakpointValues,
  },
  palette: generatePalettes(),
  typography: {
    fontFamily: [
      'var(--font-satoshi)',
      'var(--font-aktiv)',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  // shadows: 'none',
  notchedOutline: {
    borderColor: '#FF0000',
  },
  // override textTransform to none
  components: {
    MuiAutocomplete: {
      defaultProps: {
        paper: {
          boxShadow: '0px 4px 12px 0px rgba(0,0,0,0.08)',
        },
        popupIcon: (
          <i className="far fa-chevron-down  autocomplete-chevron-down" />
        ),
      },
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root': {
            paddingRight: '16px !important',
          },
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
    },
    MuiSelect: {
      defaultProps: {
        elevation: 0,
        IconComponent: (props) => (
          <i
            {...props}
            className="fas fa-chevron-down fa-xs default-chevron-down"
          />
        ),
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          '&:before': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: { sx: { borderRadius: '12px' } },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: 4,
          paddingRight: 4,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: 12,
          paddingRight: 12,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors['default-line'],
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors['default-line'],
          '&.Mui-checked.MuiRadio-colorPrimary': {
            color: colors['bgh-blue'],
          },
          '.warning &.Mui-checked.MuiRadio-colorPrimary': {
            color: colors['warning'],
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        outlined: {
          borderColor: 'rgba(0 , 0, 0, 0.1)',
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: colors.primary,
            borderColor: colors.primary,
          },
        },
        clean: {
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'rgba(255,255,255,0)',
            borderColor: 'rgba(255,255,255,0)',
          },
        },
        root: {
          '&:hover': {
            boxShadow: `0px 4px 15px rgba(0, 0, 0, 0.06),0px 4px 30px rgba(113, 204, 152, 0.09)`,
            transitionDuration: '200ms',
            '.Button-endIcon i': {
              transform: 'translateX(4px)',
              transitionDuration: '200ms',
            },
          },
          '.Button-endIcon i': {
            transitionDuration: '200ms',
          },
          borderColor: colors['default-line'],
          textTransform: 'none',
          fontWeight: 600,
          borderRadius: '2rem',
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
          fontSize: '1.2rem',
          // paddingLeft: '2rem',
          // paddingRight: '2rem',
        },
        sizeSmall: {
          [breakpoints.up('sm')]: {
            paddingLeft: '1.5rem',
            paddingRight: '1.65rem',
          },
          paddingTop: '0.4rem',
          paddingBottom: '0.4rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          fontSize: '1rem',
        },
        sizeMedium: {
          paddingTop: '13px',
          paddingBottom: '13px',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          fontSize: '1rem',
          borderRadius: '0.5rem',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          '& .MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal':
          {
            paddingTop: '0.75rem',
            paddingBottom: '0.75rem',
            minHeight: '56px',
            fontSize: '1rem',
            borderColor: `${colors['bgh-blue-border']}`,
            textTransform: 'none',
          },
          '& .MuiToggleButtonGroup-groupedHorizontal:first-child': {
            borderTopLeftRadius: '0.5rem',
            borderBottomLeftRadius: '0.5rem',
          },
          '& .MuiToggleButtonGroup-groupedHorizontal:last-child': {
            borderTopRightRadius: '0.5rem',
            borderBottomRightRadius: '0.5rem',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: `${colors['bgh-white']}`,
          border: 'inherit',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        clean: {
          '&:hover': {
            backgroundColor: 'rgba(107,125,111,0)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
          '&:focus': {
            boxShadow: `0px 4px 15px rgba(0, 0, 0, 0.06),0px 4px 30px rgba(113, 204, 152, 0.09)!important`,
          },
          '.MuiOutlinedInput-notchedOutline': {
            transition: 'all 0.1s ease-in-out !important',
            borderColor: `${colors['bgh-blue-border']}`,
          },
          // '.InputBase-adornedStart i, .InputBase-adornedStart h1': {
          //   color: `${colors['bgh-blue']}`,
          //   padding: '0 5rem',
          // },

          // 'input::placeholder, textarea::placeholder': {
          //   color: `${colors['bgh-field-placeholder']}!important`,
          // },
          // '&:hover fieldset': {
          //   borderColor: `${colors.primary}!important`,
          // },
          // '.MuiOutlinedInput-input::-webkit-input-placeholder': {
          //   opacity: `${1} !important`,
          // },
          // '.MuiInputLabel-animated': {
          //   color: `${colors['bgh-field-label']}!important`,
          //   fontWeight: 400
          // },
        },
        inputAdornedStart: {
          paddingLeft: '0.65rem', // Ex. Contact form
        },
        inputAdornedEnd: {
          paddingRight: '0.65rem', //
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // input padding
          // '& .MuiOutlinedInput-input': {
          //   margin: '0rem !important',
          //   color: 'black'
          // },
          // '.MuiOutlinedInput-notchedOutline': {
          //   borderColor: `${colors['bgh-blue-border']} !important`,
          // },
          // // error
          // '.Mui-error .MuiOutlinedInput-notchedOutline': {
          //   borderColor: `#d32f2f !important`,
          // },
          // '&:focus .Mui-error fieldset': {
          //   borderColor: `#d32f2f !important`,
          // },
          // '&:hover .Mui-error fieldset': {
          //   borderColor: `#d32f2f !important`,
          // },

          '.MuiInputBase-adornedStart h1, .MuiInputBase-adornedStart i, .MuiInputBase-adornedEnd i':
          {
            color: `${colors['primary']} !important`,
            padding: '0 0.3rem',
          },
          '&:hover .MuiInputBase-adornedStart h1, &:hover .MuiInputBase-adornedStart i':
          {
            color: `${colors['bgh-blue']} !important`,
          },
          // '.MuiInputBase-multiline.MuiInputBase-adornedStart i': {
          //   paddingTop: '0.5rem',
          // },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h6',
          subtitle2: 'p',
          body1: 'p',
          body2: 'span',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          width: 'auto',
          height: 'auto',
          overflow: 'visible',
          boxSizing: 'content-box',
          // padding: 3,
          fontSize: 'inherit',
        },
      },
      defaultProps: {
        component: 'i',
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          maxWidth: '20rem',
          lineHeight: '150%',
          textAlign: 'center',
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          width: '100%',
          paddingTop: '1rem',
          position: 'relative',
          zIndex: 1,
          [breakpoints.up('md')]: {
            width: '50%',
          },
          //override line connector color
          '.MuiStepConnector-line': {
            border: '0.1rem solid #f0f1f5',
            marginTop: '0.5rem',
            position: 'relative',
            zIndex: -1,
          },
          '.Mui-completed .MuiStepConnector-line, .Mui-active .MuiStepConnector-line, ':
          {
            borderColor: 'rgba(0,20,255,0.15)',
          },
          //override step icon bg color and size
          '.MuiStepIcon-root': {
            width: '1.5rem',
            height: '1.5rem',
            border: '3px solid #f0f1f5',
            borderRadius: '50%',
          },
          '.MuiSvgIcon-root': {
            width: '2rem !important',
            height: '2rem !important',
            fill: 'transparent',
          },
          '.Mui-active .MuiSvgIcon-root': {
            fill: colors['primary'],
          },
          '.Mui-completed .MuiSvgIcon-root': {
            fill: colors['bgh-blue-light'],
          },
          '.MuiStepLabel-iconContainer.Mui-active': {
            border: '1px solid rgba(0,20,140,0.1)',
            borderRadius: '50%',
          },
          '.MuiStepLabel-iconContainer.Mui-active::before': {
            content: '""',
            position: 'absolute',
            width: '2rem',
            height: '2rem',
            borderRadius: '50%',
            animation: 'blue-pulse 0.8s infinite',
            animationTimingFunction: 'ease-in-out',
          },
          '.MuiStepIcon-text': {
            fill: '#d5d8e3',
            fontWeight: 'bold',
          },
          '.Mui-active .MuiStepIcon-text': {
            fill: '#fff',
          },
          '.MuiStepLabel-label': {
            fontSize: '2.6vw',
            marginTop: '1px',
            [breakpoints.up('xs')]: {
              fontSize: '0.8rem',
            },
          },
          '.MuiStepLabel-alternativeLabel': {
            marginTop: '0.15rem !important',
          },
          '.Mui-completed': {
            color: `${colors['bgh-blue-light']} !important`,
          },
          '.Mui-active': {
            color: `${colors['primary']} !important`,
            fontWeight: 'bold !important',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          //Input color
          'input, textarea, select': {
            color: `${colors['bgh-field-value']}!important`,
            fontWeight: 600,
            backgroundColor: `transparent`,
          },
          '&:hover fieldset': {
            borderColor: `${colors['bgh-blue']} !important`,
          },
          '.Mui-error input, .Mui-error  textarea, .Mui-error  select': {
            color: `${colors['bgh-red']} !important`,
          },
          '.Mui-error:hover fieldset': { borderColor: `#ff000080 !important` },
          'input::placeholder, textarea::placeholder, select::placeholder': {
            color: `${colors['bgh-field-label']} !important`,
            opacity: 1,
            fontWeight: 400,
          },
          'input:hover::placeholder, textarea:hover::placeholder, select:hover::placeholder':
          {
            color: `${colors['bgh-field-placeholder']} !important`,
          },
          '.MuiInputLabel-animated:not(.Mui-error)': {
            color: `${colors['bgh-field-label']}`,
            fontWeight: 400,
          },
          '&:hover .MuiInputLabel-animated:not(.Mui-error), &:focus .MuiInputLabel-animated:not(.Mui-error)':
          {
            color: `${colors['bgh-field-placeholder']}`,
          },
          '.MuiInputLabel-animated.MuiInputLabel-shrink:not(.Mui-error)': {
            // opacity: 1, //has side effect on search field (ex. center page)
            color: `${colors['bgh-field-label']}`,
          },
          //radio button
          '.MuiRadioGroup-root .MuiFormControlLabel-root': {
            margin: '0',
            width: '100%',
            padding: '0',
            position: 'relative',
          },
          '.MuiRadioGroup-root.is-big .MuiFormControlLabel-root': {
            padding: '0.85rem 0.25rem',
            border: '0.1rem solid #f0f1f5',
            boxShadow: '0px 4px 20px 0px rgba(74, 74, 74, 0.10)',
            borderRadius: '0.5rem',
            height: '100%',
            [breakpoints.up('sm')]: {
              padding: '1.1rem 0.5rem',
            },
          },
          '.MuiRadioGroup-root .MuiFormControlLabel-label': {
            display: 'flex',
            justifyContent: 'space-between',
            gap: '0.5rem',
            alignItems: 'center',
            width: '100%',
            fontWeight: 'bold',
            paddingRight: '1.1rem',
            transition: 'all 0.1s ease-out',
            zIndex: '0',
            opacity: '0.9',
          },
          '.MuiRadioGroup-root .MuiFormControlLabel-label i': {
            width: '1.5rem',
            height: '1.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '.MuiRadioGroup-root .MuiListItemButton-root': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: '0',
            borderRadius: '0.5rem',
          },
          '.Mui-checked': {
            zIndex: '1',
          },
          '.MuiRadioGroup-root .Mui-checked + .MuiFormControlLabel-label': {
            color: colors['bgh-blue'],
            opacity: '1',
          },
          '.MuiRadioGroup-root .Mui-checked + .MuiFormControlLabel-label:before':
          {
            color: colors['bgh-blue'],
            border: '0.15rem solid rgba(0, 102, 255, 0.2)',
            backgroundColor: colors['bgh-blue-alpha'],
            boxShadow: '0 8px 20px rgba(0, 102, 255, 0.12)',
            position: 'absolute',
            content: '""',
            width: '100%',
            top: 0,
            bottom: 0,
            left: 0,
            zIndex: -2,
            borderRadius: '0.4rem',
            opacity: '0.9',
          },
          '.MuiRadioGroup-root .warning .Mui-checked + .MuiFormControlLabel-label':
          {
            color: colors['warning'],
            borderColor: colors['warning'],
            backgroundColor: 'white',
          },
          '.MuiRadioGroup-root .warning .Mui-checked + .MuiFormControlLabel-label:before':
          {
            backgroundColor: colors['warning'],
            border: '0.15rem solid rgba(255, 255, 105, 1)',
            opacity: '0.1',
          },
          '.MuiRadioGroup-root .Mui-checked + .MuiFormControlLabel-label i': {
            opacity: '1',
          },
          '.MuiRadioGroup-root.is-minimal .MuiRadio-root': {
            display: 'none',
          },
          '.MuiRadioGroup-root.is-minimal MuiFormControlLabel-root': {
            width: 'auto',
          },
          '.MuiRadioGroup-root.is-minimal .MuiFormControlLabel-label': {
            boxShadow: '0 8px 20px rgba(0, 102, 255, 0.12)',
            paddingRight: '0.65rem',
            paddingLeft: '0.65rem',
            paddingTop: '0.65rem',
            paddingBottom: '0.65rem',
            justifyContent: 'center',
            position: 'relative',
            width: 'auto',
            borderRadius: '0.5rem',
            backgroundColor: 'white',
          },
          '.MuiRadioGroup-root.is-minimal .Mui-checked + .MuiFormControlLabel-label:before':
          {
            display: 'none',
          },
          '.MuiRadioGroup-root.is-minimal .Mui-checked + .MuiFormControlLabel-label':
          {
            color: 'white',
            backgroundColor: colors['bgh-blue'],
            animation: 'blue-bg 0.8s infinite',
          },
          //Native Select Label
          // '.MuiInputLabel-outlined': {
          //   color: `${colors['bgh-field-value']}!important`,
          //   size: '1.5rem',
          //   fontWeight: 600,
          // },
          '.MuiNativeSelect-outlined': {
            paddingRight: '1rem !important',
          },
          //Text field multiline
          '.MuiOutlinedInput-root.MuiInputBase-multiline ': {
            display: 'flex',
            alignItems: 'stretch',
          },
          '.MuiOutlinedInput-root.MuiInputBase-multiline .MuiInputAdornment-outlined':
          {
            height: '100%',
            maxHeight: 'auto',
          },
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          // width: '92.5%',
          '.MuiPickersCalendarHeader-label': {
            color: colors['bgh-blue'],
            fontWeight: 'bold',
          },
          // '.MuiPickersCalendarHeader-root': {
          //   paddingLeft: '1rem',
          //   paddingRight: '0.25rem',
          // },
          '.MuiSvgIcon-root.MuiPickersCalendarHeader-switchViewIcon': {
            fill: colors['bgh-blue'],
          },
          '.MuiPickersDay-dayWithMargin': {
            fontSize: '0.85rem',
          },
          '.MuiPickersDay-dayWithMargin.Mui-selected, .MuiPickersYear-yearButton.Mui-selected':
          {
            fontWeight: 'bold',
            animation: 'blue-bg 0.8s infinite',
            animationTimingFunction: 'ease-in-out',
          },
          '.MuiDayCalendar-weekDayLabel': {
            fontSize: '0.85rem',
            fontWeight: 'bold',
          },
          '.MuiDayCalendar-weekDayLabel, .MuiPickersDay-root': {
            borderRadius: '0.25rem',
          },
          '.MuiPickersDay-today': {
            border: `0.1rem dotted ${colors['bgh-gray-line']} !important`,
          },
        },
      },
    },
    //over ride mui modal backdrop color
    MuiModal: {
      styleOverrides: {
        root: {
          //not invisible
          '.MuiModal-backdrop:not(.MuiBackdrop-invisible)': {
            backgroundColor: 'rgba(155, 155, 155, 0.5)',
            backdropFilter: 'blur(10px)',
          },
        },
      },
    },

    //override datepicker
    // MuiPickersDay: {
    //   styleOverrides: {
    //     day: {
    //       color: colors['bgh-blue'],
    //     },
    //     daySelected: {
    //       backgroundColor: colors['bgh-blue'],
    //       color: colors['bgh-white'],
    //       '&:hover': {
    //         backgroundColor: colors['bgh-blue'],
    //       }
    //     }
    //   }
    // },
  },
})

export default theme
